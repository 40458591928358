import 'style/donate.css'
import ListGroup from 'react-bootstrap/ListGroup';
import Card from 'react-bootstrap/Card';
import { Col, Row } from "react-bootstrap"
import { useState, useEffect } from "react"
import { Icon } from 'semantic-ui-react'
import { uploadFile, getFile } from '../components/s3Images'
import { getDonees, addDonees, deleteDonees, updateDonees } from '../components/apiConnection'
import DoneeAddForm from '../components/DoneeAddForm'
import DoneeEditForm from '../components/DoneeEditForm'

function Donate() {
  const [doneesData, setDoneesData] = useState([])
  const visible = false
  
  useEffect(() => {
    donees()
  }, [])

  const donees = async() => {
    setDoneesData(await getDonees())
  }

  const handleEdit = () => {

  }

  return (
    <div className="donate-container">
      <div className="donees">
        <ListGroup>
          <button>
            <DoneeAddForm /> 
            <Icon
              className='icon' 
              name='add'
              size='large'
            />
          </button>
        {doneesData.map((donee) => {
          return(
            <ListGroup.Item>                      
              <Card>
                <Row className='donee-item'>
                  <Col className='donee-image'>
                    <Card.Img src="#"/>
                  </Col>
                  <Col className='donee-info'>
                    <Card.Body>
                      <Card.Title>{donee.name}</Card.Title>
                      <Card.Text>{donee.description}</Card.Text>
                      <Card.Link />
                    </Card.Body>
                  </Col>
                  <Col className='donee-icons'>
                    <Icon 
                      className='icon'
                      name='edit'
                      size='large'
                    />
                    <Icon 
                      className='icon'
                      name='delete'
                      size='large'
                    />
                  </Col>
                  </Row>
              </Card>
            </ListGroup.Item>
          )})
        }
        </ListGroup>
      </div>
    </div>
  )
}

export default Donate
