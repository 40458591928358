import { useLocation, Navigate } from "react-router";

export const setToken = (token) => {
    localStorage.setItem('user-token', token)
}

export const fetchToken = (token) => {
    return localStorage.getItem('user-token')
}

export function RequiredToken({children}) {
    const auth = fetchToken()
    const location = useLocation()

    if(!auth) {
        return <Navigate to='/' state={{from: location}}/>
    }

    return children
}