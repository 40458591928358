import 'style/forgot.css'
import { useNavigate } from "react-router"
import { useState } from "react"
import RecoveryEmail from "./RecoveryEmail"

function ForgotPassword() {

  const navigate = useNavigate()
  const [email, setEmail] = useState({
    'email': ""
  })

  const checkEmail = async(e) => {
    e.preventDefault()

    const requestOptions = {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(email)
    }

    await fetch("http://127.0.0.1:8000/forgot_password", requestOptions)
      .then(function(response) {
        console.log(response)
        return response.json()
      })
      // .then(function(json) {
      //   return json
      // })
  }

  const handleInputChange = (e) => {
    const {name, value} = e.target
    setEmail({
      ...email,
      [name]: value
    })
    console.log(email)
  }
  
  const handleSubmit = () => {
    if(checkEmail) {
      return RecoveryEmail(email)
    }
  }

  return (
    <div className="form-wrapper">
      <div className="forgot">
        <div className="forgot-header">
          <span>Request password reset email</span>
        </div>
        <form onClick={handleSubmit}>
          <div className='email-reset'>
            <div className="forgot-input-box">
              <input 
                type="text"
                className="forgot-input-field"
                placeholder="Your account email"
                name="email"
                onInput={handleInputChange}
                required
              />
            </div>
            <div className="forgot-submit">
              <input
                type="submit"
                className="forgot-input-submit"  
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ForgotPassword
