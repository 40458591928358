import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Routes, Route, Outlet } from 'react-router-dom'
import Home from 'pages/Home'
import User from 'pages/User'
import Admin from 'pages/Admin'
import Contact from 'pages/Contact'
import About from 'pages/About'
import NavBar from 'components/navbar'
import Donate from 'pages/Donate'
import ForgotPassword from 'pages/Forgot'
import Footer from 'components/footer'
import ProtectedRoute from 'components/authenticateUser'

const App = () => {
  
  return (
    <Routes>
      <Route 
        path="user" 
        element={
          <User/>
        }
      />
      <Route 
        path="admin" 
        element={
          <Admin/>
        } 
      />
      <Route 
        path="forgot" 
        element={
          <ForgotPassword />
        } 
      />

      <Route
        element={
          <>
            <ProtectedRoute>
              <NavBar />
              <Outlet />
              <Footer />
            </ProtectedRoute>
          </>
        }
      >
        <Route 
          path="/" 
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          } 
        />
        <Route 
          path="about" 
          element={
            <ProtectedRoute>
              <About />
            </ProtectedRoute>
          } 
        />
        <Route 
          path="contact" 
          element={
            <ProtectedRoute>
              <Contact />
            </ProtectedRoute>
          } />
        <Route 
          path="donate" 
          element={
            <ProtectedRoute>
              <Donate />
            </ProtectedRoute>
          } 
        />
      </Route>
    </Routes>
  )
}

export default App
