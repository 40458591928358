// import 'style/login.css'

function RecoveryEmail(email) {
   return (
    <div className='message-wrapper'>
        <div className='message'>
            <h1> Email Sent </h1>
            <p>We have sent you an email to {email}. Thank you for using our site.</p>
        </div>
    </div>
   ) 
}

export default RecoveryEmail