/*
    API CALLS:
        1. GetDonees()
        2. AddDonees()
        3. DeleteDonees()
        4. UpdateDonees()d
*/

export async function getDonees() {
    return await fetch("https://api.giftfidel.com/get_donees")
        .then(response => {
            return response.json()
        })
}

export async function addDonees(params) {
    const requestOptions = {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(params)
    }
  
    await fetch("https://api.giftfidel.com/add_donees", requestOptions)
      .then(function(response) {
        return response.json()
      })
}
  
export async function deleteDonee(params) {
    const requestOptions = {
      method: "DELETE",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(params)
    }
  
    await fetch("https://api.giftfidel.com/delete_donees", requestOptions)
      .then(function(response) {
        return response.json()
      })
}
  
export async function updateDonees(params) {
    const requestOptions = {
      method: "PATCH",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(params)
    }
  
    await fetch("https://api.giftfidel.com/update_donees", requestOptions)
      .then(function(response) {
        return response.json()
      })
}