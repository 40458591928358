import { Link } from 'react-router-dom'
import 'style/home.css'
import Background from 'assets/ethiopia_kids.jpeg'
import { ResponsivePie } from '@nivo/pie'

const pieData = [
  {
    id: 'Helped',
    label: 'Helped',
    value: 1000,
    color: 'hsl(319, 70%, 50%)',
  },
  {
    id: 'Getting Help',
    label: 'Getting Help',
    value: 2000,
    color: 'hsl(76, 70%, 50%)',
  },
  {
    id: 'Not Helped',
    label: 'Not Helped',
    value: 7500,
    color: 'hsl(238, 70%, 50%)',
  },
]

function Home() {
  return (
    <>
      <div className="home">
        <div
          className="home-page-content"
          style={{ backgroundImage: `url(${Background})` }}
        >
          <div className="mission-statement">
            <h1>Mission Statement</h1>
            <p> 
              At Ahadu Fidel, we are driven by the unwavering commitment to empower underprivileged children in Ethiopia. By facilitating direct connections between compassionate donors and deserving recipients, we believe that every child in Ethiopia deserves the chance to thrive, whether by supporting a family or an underfunded school. In circumventing unnecessary intermediaries, our aim is to streamline the process of giving and receiving support, ensuring a more impactful and efficient charitable experience.
              <br />
              <br />
              Our platform serves as a bridge, connecting donors who are eager to make a positive difference with individuals, families, and communities in Ethiopia in need. Whether it's providing assistance directly to a family or supporting an underfunded school, our transparent and efficient space enables direct, meaningful contributions that directly impact the lives of underprivileged children.
              <br />
              <br />
              Committed to creating a community where generosity knows no bounds, we recognize that every contribution, big or small, plays a crucial role in shaping a brighter future for the children of Ethiopia who need it most. Through open communication, trust, and collaboration, we strive to build a network that transcends socioeconomic barriers, fostering a sense of unity and shared responsibility for the well-being of underprivileged kids.
              <br />
              <br />
              Join us at Ahadu Fidel as we work towards a world where the potential of every child in Ethiopia is unlocked, and where the power of giving transforms the lives of underprivileged kids—whether by supporting families or underfunded schools—without unnecessary intermediation. Together, we can create a lasting impact and bring hope, joy, and opportunity to the children of Ethiopia who need it most.
            </p>
            <Link to="/donate">
              <div className="donate-button">
                <button className="button">
                  <span className="text">Donate</span>
                  <span>Click Here</span>
                </button>
              </div>
            </Link>
          </div>
        </div>
        <div className="responsive-pie-chart">
          <div className="pie-data">
            <ResponsivePie
              data={pieData}
              margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
              height={450}
              activeOuterRadiusOffset={20}
              innerRadius={0.35}
              padAngle={1}
              cornerRadius={5}
            />
          </div>
        </div>
        <div className="picture-panel"></div>
      </div>
    </>
  )
}

export default Home
