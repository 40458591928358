import 'style/user.css'
import kids from 'assets/ethiopian_happy_kids.jpeg'
import { useNavigate } from "react-router-dom"
import { useState } from "react"
import { setToken, fetchToken } from "../components/Auth"
import { validEmail, validPassword, validPhoneNumber } from "components/regex.js"

function User() {
    /**
   * Create the user Login
   */

    const navigate = useNavigate()
    const [createInputFields, createSetInputFields] = useState({
      'first_name': "",
      'last_name': "",
      'email': "",
      'phone_number': "",
      'password': "",
      'address': "",
      'user_type': "user"
    })
  
    const [errors, setErrors] = useState({})
  
    const createHandleInputChange = (e) => {
      const { name, value } = e.target
  
      createSetInputFields({
        ...createInputFields,
        [name]: value
      })
    }
  
    const validateForm = () => {
      let isValid = true;
      const newErrors = {}
  
      console.log("Email: " + validEmail.test(createInputFields.email))
      console.log("Password: " + validPassword.test(createInputFields.password))
      console.log("Phone Number: " + validPhoneNumber.test(createInputFields.phone_number))
      console.log("-------------")
  
      if(createInputFields.first_name === "") {
        newErrors.firstName = "First Name is required"
        isValid = false
      } 
  
      if(createInputFields.last_name === "") {
        newErrors.lastName = "Last Name is required"
        isValid = false
      } 
  
      if(createInputFields.email === "") {
        newErrors.email = "Email is required"
        isValid = false
      } else {
          if(!validEmail.test(createInputFields.email)) {
            newErrors.email = "Email format is incorrect (example@gmail.com)"
            isValid = false
          } else {
            newErrors.email = ""
          }
      }
  
      if(createInputFields.phone_number === "") {
        newErrors.phoneNumber = "Phone Number is required"
        isValid = false
      } else {
          if(!validPhoneNumber.test(createInputFields.phone_number)) {
            newErrors.phoneNumber = "Phone number must contain at least 10 digits"
            isValid = false
          } else {
            newErrors.phoneNumber = ""
          }
      }
  
      if(createInputFields.password === "") {
        newErrors.password = "Password is required"
        isValid = false
      } else {
          if(!validPassword.test(createInputFields.password)) {
            newErrors.password = "Password must contain at least 1 capital letter, number and special character (!_@#$%)"
            isValid = false
          } else {
            newErrors.password = ""
          }
      }
  
      setErrors(newErrors)
      return isValid
    }
  
    const handleSubmit = (e) => {
      e.preventDefault()
  
      if (validateForm()) {
        createAccount()
      } 
    }
  
    const createAccount = async() => {
      const requestOptions = {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(inputFields)
      }
      
      await fetch("https://api.giftfidel.com/create", requestOptions)
        .then(function(response) {
          return response.json()
        })
        .then(function(json) {
          console.log(json)
          if(json.status === "success") {
            navigate('/')
          } else {
            alert(json.status)
            navigate('/user')
          }
        })
      }
  //----------------------------------------------------------------------------

  const [inputFields, setInputFields] = useState({
    'username': "",
    'password': "",
    'user_type': "user"
  })

  const handleInputChange = (e) => {
    const { name, value } = e.target

    setInputFields({
      ...inputFields,
      [name]: value
    })
  }

  const login = async(e) => {
    e.preventDefault()

    const requestOptions = {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(inputFields)
    }

    // await fetch("https://api.giftfidel.com/login", requestOptions)
    await fetch("http://127.0.0.1:8000/login", requestOptions)
      .then(function(response) {
        return response.json()
      })
      .then(function(json) {
        console.log(json)
        if(json.token) {
          setToken(json.token)
          navigate('/')
        } else {
          alert(json.status)
          navigate('/user')
        }
      })
  }

  const [isLoginForm, setIsLoginForm] = useState(true);
  const toggleForm = () => {
    setIsLoginForm(!isLoginForm)
  }

  return (
    <div className='user-container'>
      <div className='user-switcher-container'>
        <div className='user-form-container'>
          <div className={`user-switcher ${isLoginForm ? '' : 'rotate'}`}>
          {isLoginForm ?(
            <div className="user-login-form-wrapper">
              <div className="user-login">
                <div className="user-login-header">
                  <span>User Login</span>
                </div>
                <form onSubmit={login}>
                  <div className="user-input-box">
                    <input 
                      type="text" 
                      id="email-phone-number" 
                      className="user-input-field" 
                      name="username" 
                      onChange={handleInputChange} 
                      required
                    />
                    <label htmlFor="email-phone-number" className="user-label">
                      Email or Phone Number
                    </label>
                  </div>
                  <div className="user-input-box">
                    <input 
                      type="password" 
                      id="pass" 
                      name="password" 
                      className="user-input-field" 
                      onChange={handleInputChange} 
                      required
                    />
                    <label htmlFor="pass" className="user-label">
                      Password
                    </label>
                  </div>
                  <div className="user-forgot-text">
                    <a href="/forgot">Forgot password?</a>
                  </div>
                  <div className="user-input-box">
                    <input 
                      type="submit" 
                      className="user-input-submit" 
                      value="Login" 
                    />
                  </div>
                </form>
                <div className='switch-button'>
                  <span>
                    Don't have an account?&nbsp;
                    <button className='rotate-button' onClick={toggleForm}> 
                      {isLoginForm ? 'Create an account' : 'I have an account'}
                    </button>
                  </span>
                </div>
                <div className='user-login-change'>
                  <span>
                    <a href='/admin'>Admin Login</a>
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <div className='user-create-form-wrapper'>
              <div className="user-create">
                <div className="user-create-header">
                  <span>Create Account</span>
                </div>
                <form className="user-create-form" onSubmit={handleSubmit}>
                  <div className="user-create-input-box">
                    <input
                      className="user-create-input-field"
                      type="text"
                      id="first-name"
                      name="first_name"
                      value={createInputFields.first_name}
                      onChange={createHandleInputChange}
                    />
                    <label className="user-create-label">First Name: </label>
                  {errors.firstName && <div className="error">{errors.firstName}</div>}
                  </div>
                  <div className="user-create-input-box">
                    <input
                      className="user-create-input-field"
                      type="text"
                      id="last-name"
                      name="last_name"
                      value={createInputFields.last_name}
                      onChange={createHandleInputChange}
                    />
                    <label className="user-create-label">Last Name: </label>
                    {errors.lastName && <div className="error">{errors.lastName}</div>}
                  </div>
                  <div className="user-create-input-box">
                    <input
                      className="user-create-input-field"
                      type="text"
                      id="email"
                      name="email"
                      value={createInputFields.email}
                      onChange={createHandleInputChange}
                    />
                    <label className="user-create-label">Email: </label>
                  {errors.email && <div className="error">{errors.email}</div>}
                  </div>
                  <div className="user-create-input-box">
                    <input
                      className="user-create-input-field"
                      type="text"
                      id="phone-number"
                      name="phone_number"
                      value={createInputFields.phone_number}
                      onChange={createHandleInputChange}
                    />
                    <label className="user-create-label">Phone Number: </label>
                    {errors.phoneNumber && <div className="error">{errors.phoneNumber}</div>}
                  </div>
                  <div className="user-create-input-box" id >
                    <input
                      className="user-create-input-field"
                      type="password"
                      id="password"
                      name="password"
                      value={createInputFields.password}
                      onChange={createHandleInputChange}
                    />
                    <label className="user-create-label">Password: </label>
                    {errors.password && <div className="error">{errors.password}</div>}
                  </div>
                  <div className="user-create-input-box">
                    <input
                      className="user-create-input-field"
                      type="text"
                      id="address"
                      name="address"
                      value={createInputFields.address}
                      onChange={createHandleInputChange}
                    />
                    <label className="user-create-label">Address: </label>
                  </div>
                  <div className="user-create-submit-box">
                    <input type="submit" className="user-create-input-submit" value="Create Login" />
                    {/* <input type="button" name="log" value="Login" /> */}
                  </div>
                </form>
                <div className='switch-button'>
                  <span>
                    I already have an account:&nbsp;
                    <button className='rotate-button' onClick={toggleForm}> 
                      {isLoginForm ? 'Create an account' : 'Go To Login'}
                    </button>
                  </span>
                </div>
              </div>
            </div>
          )}
          </div>
        </div>
      </div>
      <div className="user-picture">
        <img src={kids} alt=""></img>
      </div>
    </div>
  )
}

export default User