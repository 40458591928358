import { useState } from 'react';
import 'style/donee-add-form.css'

export default function DoneeAddForm() {

    // const handleFileChange = (e) => {
    //     // Uploaded file
    //     const files = e.target.files[0];
    //     // Changing file state
    // }

    // const handleSubmit = () => {

    // }

    return(
        <div className="addForm">
            <form>
                <input type="file" />
                <label></label>
                <input type/>

                <label></label>
                <input />
            </form>
        </div>
    )
}