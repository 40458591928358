import 'style/footer.css'

function Footer() {
  return (
    <>
      <div className="footer">
        <div className="container row">
          <div className="footer-col">
            <h4>Partners</h4>
            <ul>
              <li>
                <a href="">Books</a>
              </li>
              <li>
                <a href="">Clothes</a>
              </li>
              <li>
                <a href="">School</a>
              </li>
            </ul>
          </div>
          <div className="footer-col">
            <h4>About Us</h4>
            <ul>
              <li>
                <a href="">Learn</a>
              </li>
              <li>
                <a href="">Apply</a>
              </li>
              <li>
                <a href="">Share</a>
              </li>
            </ul>
          </div>
          <div className="footer-col">
            <h4>follow us</h4>
            <ul>
              <li>
                <a href="#" className="fa fa-facebook">
                  <span> Facebook </span>
                </a>
              </li>
              <li>
                <a href="#" className="fa fa-twitter">
                  <span> Twitter </span>
                </a>
              </li>
              <li>
                <a href="#" className="fa fa-google">
                  <span> Google </span>
                </a>
              </li>
              <li>
                <a href="#" className="fa fa-linkedin">
                  <span> Linkedin </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        {/* <select class="ui dropdown">
          <option value="">Select a Partner</option>
          <option value="1">
            <Link to="/about">Books</Link>
          </option>
          <option value="0">
            <Link to="/about">Foods</Link>
          </option>
        </select> */}
      </div>
    </>
  )
}

export default Footer
