import 'style/contact.css'

function Contact() {
  return (
    <div className="contact-us-container">
      <div className="contact-us-info">
        <div className="contact-header">
          <span>Contact Us</span>
        </div>
        <div className="contact-input-box">
          <label className="contact-label">Your Name</label>
          <input
            className="contact-input-field"
            type="text"
            id="fname"
            name="fname"
          />
        </div>
        <div className="contact-input-box">
          <label className="contact-label">Phone Number</label>
          <input
            className="contact-input-field"
            type="text"
            id="lname"
            name="lname"
          />
        </div>
        <div className="contact-input-box">
          <label className="contact-label">Subject</label>
          <input
            className="contact-input-field"
            type="text"
            id="lname"
            name="lname"
          />
        </div>
        <div className="contact-input-box">
          <label className="contact-label">Your Message</label>
          <textarea className="contact-text-box" rows="6" cols="50"></textarea>
        </div>
        <div className="input-box">
          <a href="/">
            <input type="submit" className="input-submit" value="Submit" />
            {/* <input type="button" name="log" value="Login" /> */}
          </a>
        </div>
      </div>
    </div>
  )
}

export default Contact
