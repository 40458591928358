import { useNavigate, Link } from 'react-router-dom'
import 'style/navbar.css'
import logo from 'assets/logo.jpeg'

function NavBar() {

  const navigate = useNavigate()
  const logout = () => {
    localStorage.clear()
    navigate('/user')
  }

  return (
    <>
      <nav className="navbar">
        <div className="nav-logo">
          <Link to="/">
            {/* <img className="logo" src={logo} alt="logo" /> */}
            <h1>Ahadu Fidel</h1>
          </Link>
        </div>
        <div className="nav-links">
          <Link to="/about">
            <button>About</button>
          </Link>
          <Link to="/contact">
            <button>Contact Us</button>
          </Link>
          <Link to="/user">
            <button onClick={logout}>Logout</button>
          </Link>
        </div>
      </nav>
    </>
  )
}

export default NavBar
