import 'style/about.css'

function About() {
  return (
    <div className="about-container">
      <div className="text-container">
        <h1> About Us </h1>
      </div>
    </div>
  )
}

export default About
